import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, map, of, take } from 'rxjs';
import { UserClient, UserDetailsResponse } from './identity-server-client';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  constructor(private userClient: UserClient) {}

  private userDetailsCache: {
    [email: string]: {
      data: ReplaySubject<UserDetailsResponse | undefined>;
    };
  } = {};

  getUser(
    email: string,
    forceRefresh: boolean = false
  ): Observable<UserDetailsResponse | undefined> {
    if (!email) {
      return of(undefined);
    }

    const normalizedEmail = email.toUpperCase();
    if (forceRefresh) {
      const removeEntry = (
        obj: {
          [normalizedEmail: string]: {
            data: ReplaySubject<UserDetailsResponse | undefined>;
          };
        },
        key: string
      ) => {
        const { [key]: omitted, ...rest } = obj;
        return rest;
      };

      this.userDetailsCache = removeEntry(
        this.userDetailsCache,
        normalizedEmail
      );
    }

    const fromCache = this.userDetailsCache[normalizedEmail];
    if (fromCache) {
      return fromCache.data;
    }

    return this.load(normalizedEmail);
  }

  getUserName(
    email?: string,
    forceRefresh: boolean = false
  ): Observable<string> {
    if (!email) {
      return of('');
    }

    return this.getUser(email, forceRefresh).pipe(
      take(1),
      map((details) => {
        if (details && details.givenName && details.familyName) {
          return details.givenName + ' ' + details.familyName;
        }

        return email;
      })
    );
  }

  private load(email: string): Observable<UserDetailsResponse | undefined> {
    const resultSubject = new ReplaySubject<UserDetailsResponse | undefined>(1);

    this.userDetailsCache[email] = {
      data: resultSubject,
    };

    this.userClient.getUserDetails(email).subscribe({
      next: (result) => {
        resultSubject.next(result);
      },
      error: (err) => {
        resultSubject.next(undefined);
      },
    });
    return resultSubject;
  }
}

interface IUserDetailCache {
  [email: string]: {
    data: ReplaySubject<UserDetailsResponse | undefined>;
  };
}
